





































import {
  Component, Vue,
} from 'vue-property-decorator';
import ContractingStatusModerateSignatureReceivedViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/contracting-status-moderate/contracting-status-moderate-signature-received-view-model';

@Component({ name: 'ContractingStatusModerateSignatureReceived' })
export default class ContractingStatusModerateSignatureReceived extends Vue {
  transfer_status_moderate_signature_received_model = Vue.observable(
    new ContractingStatusModerateSignatureReceivedViewModel(this),
  );
}
